import { cn } from '@/lib/utils';
import { cva } from 'class-variance-authority';
import React from 'react';

const headingVariants = cva('w-fit capitalize', {
	variants: {
		type: {
			h1: 'text-5xl leading-eis-1.29 font-bold',
			h2: 'text-40xs leading-eis-1.2 font-bold',
			h3: 'text-32xs leading-tight font-bold',
			h4: 'text-2xl font-bold',
			h5: 'text-xl font-normal',
		},
		colour: {
			'eis-black': 'text-eis-black',
			'corporate-blue': 'text-corporateBlue',
		},
	},

	defaultVariants: {
		type: 'h1',
		colour: 'eis-black',
	},
});

const Heading = React.forwardRef(({ type, colour, className, ...props }, ref) => {
	return React.createElement(type, {
		className: cn(headingVariants({ type, colour, className })),
		...props,
	});
});
Heading.displayName = 'Heading';

export { Heading, headingVariants };
